import React, {useEffect, useState} from 'react'
import {FaMinus, FaPlus} from 'react-icons/fa'
import {GiButton, GiFormGroup, GiInput} from '../../components/system-ui'

interface SelectedProductQuantityProps {
  initialQty: number
  onQtyChange: (qty: number) => void
}

const SelectedProductQuantity: React.FC<SelectedProductQuantityProps> = ({
  initialQty,
  onQtyChange,
}) => {
  const [qty, setQty] = useState<number>(initialQty)

  useEffect(() => {
    setQty(initialQty)
  }, [initialQty])

  const handleQtyChange = (newQty: number) => {
    if (newQty < 1) newQty = 1
    setQty(newQty)
    onQtyChange(newQty)
  }

  // console.log('item', qty)

  return (
    <GiFormGroup className='qty-group-inquiry'>
      <GiButton size='sm' className='btn-plus' onClick={() => handleQtyChange(qty - 1)}>
        <FaMinus />
      </GiButton>
      <GiInput
        type='number'
        className='text-center'
        value={qty}
        onChange={(e) => handleQtyChange(Number(e.target.value))}
      />
      <GiButton size='sm' className='btn-min' onClick={() => handleQtyChange(qty + 1)}>
        <FaPlus />
      </GiButton>
    </GiFormGroup>
  )
}

export default SelectedProductQuantity
