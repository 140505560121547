import {IAction} from '../store'
import {
  AUTH_ERROR,
  AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SET_COMPANY,
  AUTH_SET_COUNTRY_CATALOGUE,
  AUTH_SET_SESSION_ID,
} from './AuthActionTypes'
import PouchDB from 'pouchdb'

export interface IAuthState {
  status: 'pending' | 'log-in' | 'logged-in' | 'log-out' | 'logged-out' | 'error'
  profile?: any
  session_id?: string | null
  strapi_token?: string
  error?: string
  user_info?: any
  company?: any
  catalogueContry?: any
}

const initialState: IAuthState = {
  status: 'pending',
  session_id: localStorage.getItem('session_id'),
  user_info: {},
}

const AuthReducer = (state = initialState, action: IAction) => {
  let newState: IAuthState = {...state}
  switch (action.type) {
    case AUTH_SET_SESSION_ID:
      newState.session_id = action.payload.session_id
      localStorage.setItem('session_id', action.payload.session_id)
      break
    case AUTH_LOGIN:
      newState.status = 'log-in'
      break
    case AUTH_LOGGED_IN:
      newState.status = 'logged-in'
      newState.profile = action.payload.profile
      newState.user_info = action.payload.user_info
      newState.company = action.payload.selected_company
      break
    case AUTH_SET_COMPANY:
      newState.company = action.payload.company
      newState.catalogueContry = action.payload.company.country
      if (newState.company) {
        //save for next use on refresh
        localStorage.setItem('company_id', newState.company.corporate_id)
      }
      break
    case AUTH_SET_COUNTRY_CATALOGUE:
      newState.catalogueContry = action.payload.country
      break
    case AUTH_LOGOUT:
      newState.status = 'log-out'
      break
    case AUTH_LOGGED_OUT:
      newState.status = 'logged-out'
      newState.session_id = null
      newState.profile = {}
      localStorage.removeItem('session_id')
      // destroy local db if available
      new PouchDB('catalog')
        .destroy()
        .then(() => {})
        .catch(() => {})
      break
    case AUTH_ERROR:
      newState.status = 'error'
      newState.error = action.payload.error
      newState.session_id = null
      newState.profile = {}
      localStorage.removeItem('session_id')
      new PouchDB('catalog')
        .destroy()
        .then(() => {})
        .catch(() => {})
      break
    default:
  }
  return newState
}

export default AuthReducer
