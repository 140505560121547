import {IAction} from '../store'
import {LOADING_HELP, SUCCESS_HELP, ERROR_HELP} from './HelpActionTypes'

interface HelpInterface {
  data: any
  status: 'pending' | 'success' | 'error' | 'loading'
}

const initialState: HelpInterface = {
  data: [],
  status: 'pending',
}

const HelpReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_HELP:
      newState.status = 'loading'
      break
    case SUCCESS_HELP:
      newState.status = 'success'
      newState.data = action.payload.data || []
      break
    case ERROR_HELP:
      newState.status = 'error'
      break
    default:
      break
  }
  return newState
}

export default HelpReducer
