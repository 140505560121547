import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './components/initializer/AuthInit'
import {init as initApm} from '@elastic/apm-rum'

// import "bootstrap/dist/css/bootstrap.min.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-input-2/lib/style.css'
import './styles/App.scss'

// get version from json
const {version} = require('../../package.json')

// if not localhost then init apm
if (window.location.hostname !== 'localhost') {
  initApm({
    serviceName: 'b2b-dashboard',
    serverUrl:
      'https://b3c5a287175a4da6aae19f04ebb6865a.apm.asia-southeast1.gcp.elastic-cloud.com:443',
    serviceVersion: version,
    environment: process.env.REACT_APP_BUILD_MODE,
  })
}

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
