/* eslint-disable eqeqeq */
import {useEffect, useState} from 'react'
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import {
  GiButton,
  GiCard,
  GiCardBody,
  GiCol,
  GiFormGroup,
  GiLabel,
  GiModal,
  GiModalBody,
  GiModalHeader,
} from '../system-ui'
import {
  createPaymentTransaction,
  getPaymentGatewayClientToken,
} from '../../services/payment-request/PaymentRequestService'
import {toast} from 'react-toastify'
import {topupCredits} from '../../services/corporate/LegacyService'
import moment from 'moment'
import {messages} from '../../Messages'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {actGetAccountBalance} from '../../redux/corporate/AccountBalanceActions'
import {
  SET_MODAL_DATA_ACCOUNT_BALANCE,
  SHOW_TOPUP_ACCOUNT_BALANCE,
} from '../../redux/corporate/CorporateActionTypes'
import GftForm from '../form/GftForm'
import {BiMailSend} from 'react-icons/bi'
import {SendEmail} from '../../services/email/EmailServices'
import {FaSpinner} from 'react-icons/fa'
import CurrencyInput from '../form/CurrencyInput'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import * as Sentry from '@sentry/react'
import {Link} from 'react-router-dom'
import braintreeDropin from 'braintree-web-drop-in'
import BackButton from '../form/BackButton'

const BankTransfer = ({strapiData}: {strapiData: any}) => {
  const {amount, credits} = useSelector((state: RootState) => state.accountBalance)
  return (
    <>
      {amount && parseFloat(amount) > 0 ? (
        <GiCard style={{border: '1px solid #B5B5B5', marginBottom: '16px', marginTop: '16px'}}>
          <GiCardBody>
            <GiFormGroup row>
              <GiLabel sm={6}>Account balance :</GiLabel>
              <GiCol sm={6}>
                <CurrencyInput disabled value={credits} />
              </GiCol>
            </GiFormGroup>
            <GiFormGroup row>
              <GiLabel sm={6}>Amount due :</GiLabel>
              <GiCol sm={6}>
                <CurrencyInput value={amount} disabled />
              </GiCol>
            </GiFormGroup>
          </GiCardBody>
        </GiCard>
      ) : (
        ''
      )}
      <GiCard>
        <GiCardBody>
          <div dangerouslySetInnerHTML={{__html: strapiData.modal_topup_partnership_content}}></div>
          <p style={{fontStyle: 'italic'}}>
            *Top-up will be added to your account within the same day or next business day from
            receiving payment.
          </p>
        </GiCardBody>
      </GiCard>
      <FormConfirm paymentMode={'Bank Transfer'} />
    </>
  )
}

const FormConfirm = ({paymentMode}: {paymentMode: string}) => {
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const {amountToPay, orderId} = useSelector((state: RootState) => state.accountBalance)
  const appString = useSelector((state: RootState) => state.appString.data)
  const [modalShow, setModalShow] = useState(false)
  return (
    <div className='form-confirm'>
      <div className='d-flex justify-content-center mt-5'>
        <GiButton className='rounded-pill' color='primary' onClick={() => setModalShow(true)}>
          I have made payment
        </GiButton>
      </div>
      {modalShow && (
        <GftForm
          variant='modal'
          isOpen={modalShow}
          toggle={() => setModalShow(false)}
          title='Topup Confirmation'
          fields={[
            {
              type: 'text',
              name: 'reference_number',
              label: 'Reference Number *',
              validation: ['required'],
              groupProps: {row: true},
              labelProps: {sm: 4},
              inputColProps: {sm: 8},
              inputProps: {autoFocus: true},
            },
            {
              type: 'datepicker',
              name: 'payment_date',
              label: 'Payment/Transfer Date *',
              validation: ['required'],
              groupProps: {row: true},
              labelProps: {sm: 4},
              inputColProps: {sm: 8},
              inputProps: {dateFormat: 'dd-MM-yyyy'},
            },
            {
              type: 'currency',
              name: 'amount',
              label: 'Amount (SGD) *',
              validation: ['required'],
              groupProps: {row: true},
              labelProps: {sm: 4},
              inputColProps: {sm: 8},
            },
          ]}
          initialValues={{
            payment_date: new Date(),
            amount: amountToPay,
          }}
          submitButton={(props, isSubmitting) => (
            <GiButton {...props} disabled={isSubmitting}>
              {isSubmitting ? (
                <FaSpinner className='fa-spin me-2' />
              ) : (
                <BiMailSend className='me-2' />
              )}
              SUBMIT
            </GiButton>
          )}
          onSubmit={async (values) => {
            try {
              let resp = await SendEmail({
                from_email: 'grow@giftano.com',
                recipients:
                  process.env.REACT_APP_BUILD_MODE === 'PRODUCTION'
                    ? [
                        {
                          email: 'grow@giftano.com',
                        },
                        {
                          email: 'finance@giftano.com',
                        },
                      ]
                    : [
                        {
                          email: 'dev_test_email_grow@giftano.com',
                        },
                      ],
                subject: 'Payment Notification - Giftano for Business ' + corporate_detail.name,
                html_tag_content: `<strong>Corporate Name :</strong> ${corporate_detail.name}<br/>
                                <strong>Order Number :</strong> ${orderId || 'NA'} <br/>
                                <strong>Payment Mode :</strong> ${paymentMode} <br/>
                                <strong>Reference Number :</strong> ${values.reference_number} <br/>
                                <strong>Payment Date :</strong> ${
                                  values.payment_date
                                    ? moment(values.payment_date).format('DD MMMM YYYY')
                                    : 'N/A'
                                } <br/>
                                <strong>Amount (SGD) :</strong> ${values.amount} <br/>
                                <strong>Message :</strong> - `,
              })
              // console.log(resp)
              if (resp.code === 200) {
                toast.success(messages.payment_notification_sent)
                setModalShow(false)
              } else {
                toast.warning(resp.message || messages.payment_notification_unsent)
              }
            } catch (err) {
              Sentry.captureException(err)
              console.log('🚀 ~ file: ModalTopup.tsx ~ line 149 ~ onSubmit={ ~ err', err)
              toast.warning(appString['network-error'] || 'Something went wrong')
            }
          }}
        />
      )}
    </div>
  )
}

const TabCard = ({braintreeDropinOptions = {}}: any) => {
  // const [braintreeInstance, setBraintreeInstance] = useState<any>()
  const [braintreeDeviceData, setBraintreeDeviceData] = useState<any>()
  const [isInProgress, setIsInProgress] = useState(false)
  const {financeFee, gst, amountToPay, amount, credits} = useSelector(
    (state: RootState) => state.accountBalance
  )

  const dispatch = useDispatch()
  const toggle = () => dispatch({type: SHOW_TOPUP_ACCOUNT_BALANCE, payload: {show: false}})
  const {corporate_id, corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const [gfbCCfee, setGfbCCfe] = useState(3.8)
  const [loadingCreatePayment, setLoadingCreatePayment] = useState(false)

  const [paymentOptionsView, setPaymentOptionsView] = useState(false)

  useEffect(() => {
    if (corporate_detail['transaction-settings']['gfb-credit-card-fee']) {
      setGfbCCfe(corporate_detail['transaction-settings']['gfb-credit-card-fee'])
    } else {
      setGfbCCfe(3.8)
    }
  }, [corporate_detail])

  const profile = useSelector((state: RootState) => state.auth.profile)
  const [braintreeInstance, setBraintreeInstance] = useState<any>()
  useEffect(() => {
    let viewAvailable = true
    if (paymentOptionsView) {
      if (braintreeInstance) {
        return
      }
      // check if container exist continue if not return
      if (!document.getElementById('dropinContainerrrrr')) return
      //  if container not empty then return
      if (document.getElementById('dropinContainerrrrr')?.innerHTML) return
      let params = {}
      setLoadingCreatePayment(true)
      if (profile && profile.profile_accesses) {
        let get = profile.profile_accesses.find((el: any) => el.corporate_id === corporate_id)
        if (get.role_detail.slug === 'corporate-admin') {
          params = {
            corporateId: corporate_id,
          }
        }
      }
      getPaymentGatewayClientToken(params).then((json) => {
        if (!viewAvailable) return
        if (json.code === 200) {
          const clientToken = json.data.client_token
          // check if container exist continue if not return
          if (!document.getElementById('dropinContainerrrrr')) return
          braintreeDropin
            .create({
              authorization: clientToken,
              container: '#dropinContainerrrrr',
              threeDSecure: true,
              dataCollector: {
                kount: true,
              },
              card: {
                cardholderName: {
                  required: true,
                },
              },
              // googlePay: {
              //   googlePayVersion: 2,
              //   merchantId: 'BCR2DN6TTP2ZN6KF',
              //   transactionInfo: {
              //   totalPriceStatus: 'FINAL',
              //   totalPrice: amount.toString(),
              //   currencyCode: 'SGD'
              //   },
              // },
              applePay: {
                displayName: 'Giftano',
                paymentRequest: {
                  total: {
                    label: 'Giftano',
                    amount: amountToPay ? amountToPay.toString() : '0',
                  },
                  countryCode: 'SG',
                  currencyCode: 'SGD',
                  requiredBillingContactFields: ['postalAddress'],
                  supportedNetworks: ['visa', 'masterCard', 'amex'],
                  merchantCapabilities: ['supports3DS'],
                },
              },
            })
            .then((instance: any) => {
              // console.log('🚀 ~ .then ~ instance:', instance)
              // setBraintreeInstance(instance)
              if (!viewAvailable) return
              setBraintreeInstance(instance)
              setLoadingCreatePayment(false)
            })
            .catch((err: any) => {
              if (!viewAvailable) return
              console.log('🚀 ~ .catch ~ err:', err)
              if (err._braintreeWebError) {
                Sentry.captureException(err)
                toast.error("Oops! We're unable to process your payment, please try reload.")
              }
              setBraintreeInstance(null)
            })
        }
      })
    }
    return () => {
      viewAvailable = false
    }
  }, [corporate_id, profile, paymentOptionsView, amountToPay])

  useEffect(() => {
    if (braintreeInstance) {
      braintreeInstance.on('paymentMethodRequestable', (event: any) => {
        // console.log('paymentMethodRequestable', event)
        if (event?.type === 'ApplePayCard' && event?.paymentMethodIsSelected) {
          // console.log('apple pay selected')
          payWithApplePay()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [braintreeInstance])

  const payWithApplePay = () => {
    handleTopUpClick()
    stopPaymentRequestable()
  }

  const stopPaymentRequestable = () => {
    if (braintreeInstance) {
      braintreeInstance.off('paymentMethodRequestable')
    }
  }

  // const onBraintreeInstance = (i: any) => {
  //   // console.log('instance set', i)
  //   // setBraintreeInstance(i)
  //   if (i && i._client) {
  //     braintree.dataCollector
  //       .create({
  //         client: i._client,
  //         kount: true,
  //         paypal: true,
  //       })
  //       .then((res) => {
  //         //console.log('result collector', res);
  //         setBraintreeDeviceData(res.deviceData)
  //       })
  //       .catch((err) => {
  //         Sentry.captureException(err)
  //         console.log('collector error', err)
  //       })
  //   }
  // }

  const handleTopUpClick = async () => {
    if (amountToPay) {
      if (parseFloat(amountToPay.toString()) <= 0) {
        toast.warning('Please fill in the amount you want to top up')
        return
      }
    } else {
      toast.warning('Please fill in the amount you want to top up')
      return
    }
    if (!braintreeInstance) return
    if (!braintreeInstance.isPaymentMethodRequestable()) return
    setIsInProgress(true)
    braintreeInstance.requestPaymentMethod(
      {
        threeDSecure: {
          amount: amountToPay,
        },
      },
      (err: any, payload: any) => {
        if (err) {
          console.log('err brain tree ', err)
          toast.error(err.message)
          setIsInProgress(false)
          return
        }

        setLoadingCreatePayment(true)

        const orderid = 'corporate_topup_' + corporate_id + '_' + moment().format('YYMMDDHHmm')
        createPaymentTransaction({
          payment: {
            amount: amountToPay,
            payment_method_nonce: payload.nonce,
            device_data: braintreeDeviceData,
            order_id: orderid,
          },
        })
          .then((json) => {
            // console.log('json res payment create transaction', json)
            if (json.code === 201 && json.Data && json.Data.transaction_id) {
              //call topup endpoint
              topupCredits({
                corporateid: corporate_id,
                braintree_id: json.Data.transaction_id,
                amount: amountToPay ? parseFloat(amountToPay) : 0,
                // orderid: orderid
              })
                .then((res) => {
                  // console.log('response from topup credits', res)

                  if (res.status === 1) {
                    toast.success(messages.topup_success)
                    setIsInProgress(false)
                    setLoadingCreatePayment(false)
                    toggle()

                    dispatch(actGetAccountBalance({corporateid: corporate_id}))
                  } else {
                    toast.warning(res.message || messages.topup_account_balance_failed)
                    setIsInProgress(false)
                    setLoadingCreatePayment(false)
                  }
                })
                .catch((err) => {
                  Sentry.captureException(err)
                  console.log('error topup credit', err)
                  toast.error(messages.network_error)
                  setIsInProgress(false)
                  setLoadingCreatePayment(false)
                })
            } else {
              //
              toast.warning('Error process payment, ' + json.error)
              setIsInProgress(false)
              setLoadingCreatePayment(false)
            }
          })
          .catch((err) => {
            Sentry.captureException(err)
            console.log('error', err)
            toast.error('Error process payment, please try again..!')
            setIsInProgress(false)
          })
      }
    ) // endof request payment method from braintree
  }

  return (
    <div className='topup-container mt-4'>
      {!paymentOptionsView && (
        <GiCard style={{border: '1px solid #B5B5B5', marginBottom: '16px'}}>
          <GiCardBody>
            <GiFormGroup row>
              <GiLabel sm={6}>Account balance :</GiLabel>
              <GiCol sm={4}>
                <CurrencyInput disabled value={credits} style={{textAlign: 'right'}} />
              </GiCol>
            </GiFormGroup>
            <GiFormGroup row>
              <GiLabel sm={6}>Top up amount :</GiLabel>
              <GiCol sm={4}>
                <CurrencyInput
                  placeholder='Enter amount to topup'
                  value={amount}
                  disabled={isInProgress}
                  onValueChange={(value: any) =>
                    dispatch({
                      type: SET_MODAL_DATA_ACCOUNT_BALANCE,
                      payload: {amount: value || 0},
                    })
                  }
                  onFocus={(e) => {
                    if (e.currentTarget && e.currentTarget.value) {
                      e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)
                    }
                  }}
                  style={{textAlign: 'right'}}
                  autoFocus
                />
              </GiCol>
            </GiFormGroup>
            {gfbCCfee > 0 ? (
              gst !== undefined ? (
                <>
                  <GiFormGroup row>
                    <GiLabel sm={6}>Finance Fee ({gfbCCfee}%) *</GiLabel>
                    <GiCol sm={4}>
                      <CurrencyInput
                        disabled
                        value={financeFee}
                        style={{textAlign: 'right'}}
                        decimalScale={2}
                      />
                    </GiCol>
                  </GiFormGroup>
                  <GiFormGroup row>
                    <GiLabel sm={{size: 5, offset: 1}}>Total Before GST</GiLabel>
                    <GiCol sm={4}>
                      <CurrencyInput
                        disabled
                        value={
                          amount && financeFee ? parseFloat(amount) + parseFloat(financeFee) : 0
                        }
                        style={{textAlign: 'right'}}
                        decimalScale={2}
                      />
                    </GiCol>
                  </GiFormGroup>
                  <GiFormGroup row>
                    <GiLabel sm={{size: 5, offset: 1}}>GST</GiLabel>
                    <GiCol sm={4}>
                      <CurrencyInput
                        disabled
                        value={gst}
                        style={{textAlign: 'right'}}
                        decimalScale={2}
                      />
                    </GiCol>
                  </GiFormGroup>
                  <GiFormGroup row>
                    <GiLabel sm={{size: 5, offset: 1}} style={{fontWeight: 'bold'}}>
                      Total Payable
                    </GiLabel>
                    <GiCol sm={4}>
                      <CurrencyInput
                        disabled
                        value={amountToPay}
                        style={{textAlign: 'right'}}
                        decimalScale={2}
                      />
                    </GiCol>
                  </GiFormGroup>
                  <span style={{fontStyle: 'italic', fontSize: '0.9em'}}>* Taxable Charges</span>
                </>
              ) : (
                <GiFormGroup row>
                  <GiLabel sm={6}>Total Pay (+ {gfbCCfee}% finance fee) :*</GiLabel>
                  <GiCol sm={4}>
                    <CurrencyInput disabled value={amountToPay} decimalScale={2} />
                  </GiCol>
                </GiFormGroup>
              )
            ) : (
              <GiFormGroup row>
                <GiLabel sm={{size: 5, offset: 1}} style={{fontWeight: 'bold'}}>
                  Total Payable
                </GiLabel>
                <GiCol sm={4}>
                  <CurrencyInput
                    disabled
                    value={amountToPay}
                    style={{textAlign: 'right'}}
                    decimalScale={2}
                  />
                </GiCol>
              </GiFormGroup>
            )}
          </GiCardBody>
        </GiCard>
      )}
      {paymentOptionsView && (
        <>
          <div className='d-flex gap-7 align-items-center mb-7'>
            <BackButton
              action={() => {
                setPaymentOptionsView(false)
                // setBraintreeInstance(null)
                if (braintreeInstance) {
                  braintreeInstance.teardown()
                  setBraintreeInstance(null)
                }
              }}
              style={{
                width: 'fit-content',
              }}
            />
          </div>
          <div id={'dropinContainerrrrr'}></div>

          {!loadingCreatePayment ? (
            // <DropIn
            //   options={braintreeDropinOptions}
            //   onInstance={onBraintreeInstance}
            //   onError={(err) => {
            //     toast.error('Ops something wrong while load payment form, please reload the page!', {
            //       autoClose: false,
            //     })
            //   }}
            // />
            <></>
          ) : (
            <h4 className='text-center text-muted my-5 py-3'>
              Please wait we are processing your payment...
            </h4>
          )}
        </>
      )}

      <div className='pt-4'>
        {paymentOptionsView ? (
          <GiButton
            disabled={isInProgress || loadingCreatePayment}
            type='button'
            block
            color='primary'
            onClick={handleTopUpClick}
            className='rounded-pill'
          >
            {isInProgress && <FaSpinner className='fa-spin me-2' />}
            {isInProgress ? ' PROCESS YOUR PAYMENT' : 'TOP UP NOW'}
          </GiButton>
        ) : (
          <GiButton
            type='button'
            block
            color='primary'
            onClick={() => setPaymentOptionsView(true)}
            className='rounded-pill'
            disabled={!amountToPay}
          >
            Next
          </GiButton>
        )}
      </div>
    </div>
  )
}

const TabPayNow = () => {
  const {amount, credits} = useSelector((state: RootState) => state.accountBalance)
  return (
    <>
      {amount && parseFloat(amount) > 0 ? (
        <GiCard style={{border: '1px solid #B5B5B5', marginBottom: '16px', marginTop: '16px'}}>
          <GiCardBody>
            <GiFormGroup row>
              <GiLabel sm={6}>Account balance :</GiLabel>
              <GiCol sm={6}>
                <CurrencyInput disabled value={credits} />
              </GiCol>
            </GiFormGroup>
            <GiFormGroup row>
              <GiLabel sm={6}>Amount due :</GiLabel>
              <GiCol sm={6}>
                <CurrencyInput value={amount} disabled />
              </GiCol>
            </GiFormGroup>
          </GiCardBody>
        </GiCard>
      ) : (
        ''
      )}

      <GiCard className='pay-now'>
        <GiCardBody>
          <div className='how-to'>
            <p style={{fontWeight: 'bold'}}>Via UEN:</p>
            <ul>
              <li>Launch your bank's app and access the PayNow option</li>
              <li>Select UEN option</li>
              <li>Key in UEN Number: 201433730C</li>
              <li>You will see “GIFTANO PTE. LTD.“ reflected</li>
            </ul>

            <p style={{fontWeight: 'bold'}}>Via QR Code:</p>
            <ul>
              <li>Launch your bank's app</li>
              <li>Select QR Code option</li>
            </ul>

            <img src={'https://static-cdn.giftano.com/assets/paynow_giftano.png'} alt={'Pay Now'} />

            <p style={{fontStyle: 'italic'}}>
              *Top-up will be added to your account within the same day or next business day from
              receiving payment.
            </p>

            <FormConfirm paymentMode={'Paynow'} />
          </div>
        </GiCardBody>
      </GiCard>
    </>
  )
}

const ModalTopup = () => {
  // const [amount, setAmount] = useState('0')
  // const [amountToPay, setAmountToPay] = useState(0)

  const [activeTab, setActiveTab] = useState('1')
  const accountBalance = useSelector((state: RootState) => state.accountBalance)
  const {showTopup, amount} = accountBalance
  const dispatch = useDispatch()
  const toggle = () => dispatch({type: SHOW_TOPUP_ACCOUNT_BALANCE, payload: {show: false}})
  const {corporate_detail, corporate_id} = useSelector((state: RootState) => state.auth.company)
  const strapiData = useSelector((state: RootState) => state.strapi.data)
  const [braintreeDropinOptions, setBraintreeDropinOptions] = useState<any>({})
  const profile = useSelector((state: RootState) => state.auth.profile)
  const [braintreeInstance, setBraintreeInstance] = useState<any>()

  useEffect(() => {
    // console.log('amount', amount)
    let floatAmount = parseFloat(amount || '0')
    let gfbCCfee = 3.8
    if (corporate_detail['transaction-settings']['gfb-credit-card-fee']) {
      gfbCCfee = parseFloat(corporate_detail['transaction-settings']['gfb-credit-card-fee'])
    } else {
      gfbCCfee = 3.8
    }
    if (isNaN(floatAmount)) floatAmount = 0
    if (activeTab === '1') {
      const financeFee = Math.round((gfbCCfee / 100) * floatAmount * 100) / 100
      //-- add gst
      let gst = undefined
      if (moment() >= moment('2023-02-01') || process.env.REACT_APP_BUILD_MODE !== 'PRODUCTION') {
        gst = Math.round((9 / 100) * (financeFee * 100)) / 100
      }
      // --
      const newAmountToPay =
        Math.round((floatAmount + financeFee + (gst || 0) + Number.EPSILON) * 100) / 100
      dispatch({
        type: SET_MODAL_DATA_ACCOUNT_BALANCE,
        payload: {financeFee: financeFee, gst: gst, amountToPay: newAmountToPay},
      })
    } else {
      dispatch({type: SET_MODAL_DATA_ACCOUNT_BALANCE, payload: {amountToPay: floatAmount}})
    }
  }, [amount, activeTab, dispatch, corporate_detail])

  useEffect(() => {
    if (corporate_detail.country !== 'SG') {
      setActiveTab('2')
    } else {
      setActiveTab('1')
    }
  }, [corporate_detail])

  return (
    <GiModal
      size='lg'
      isOpen={showTopup}
      toggle={toggle}
      className={'modal-topup'}
      autoFocus={false}
      backdrop='static'
      keyboard={false}
    >
      <GiModalHeader toggle={toggle}>
        <img
          alt='Logo'
          width={50}
          height={50}
          src={toAbsoluteUrl('/media/icons/top-up.svg')}
          className='me-4'
        />
        <span style={{verticalAlign: 'sub'}}>
          {corporate_detail && corporate_detail.type === 'partnership'
            ? strapiData.modal_topup_partnership_title
            : 'Top Up'}
        </span>
      </GiModalHeader>
      <GiModalBody>
        <div
          style={{
            marginBottom: '30px',
            backgroundColor: '#E6F4F2',
            borderRadius: '6px',
            padding: '20px',
          }}
        >
          <h2
            style={{
              marginBottom: '0px',
              textAlign: 'center',
              fontWeight: '500',
            }}
          >
            Please email{' '}
            <Link
              to={'#'}
              onClick={(e) => {
                window.location.href = 'mailto:grow@giftano.com'
                e.preventDefault()
              }}
            >
              grow@giftano.com
            </Link>{' '}
            for a quotation to top-up your account
          </h2>
          {/* <hr color='primary' /> */}
        </div>
        {corporate_detail && corporate_detail.type === 'partnership' ? (
          <BankTransfer strapiData={strapiData} />
        ) : corporate_detail.is_verified == 1 ? (
          <>
            <Nav className='even' tabs>
              {corporate_detail.country == 'SG' && (
                <NavItem>
                  <NavLink
                    className={activeTab === '1' ? 'active' : ''}
                    onClick={() => setActiveTab('1')}
                  >
                    Debit / Credit Card
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => setActiveTab('2')}
                >
                  Paynow
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '3' ? 'active' : ''}
                  onClick={() => setActiveTab('3')}
                >
                  Bank Transfer
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={'1'}>
                <TabCard
                  braintreeDropinOptions={braintreeDropinOptions}
                  braintreeInstance={braintreeInstance}
                />
              </TabPane>
              <TabPane tabId={'2'}>
                <TabPayNow />
              </TabPane>
              <TabPane tabId={'3'}>
                <BankTransfer strapiData={strapiData} />
              </TabPane>
            </TabContent>
          </>
        ) : (
          <div>
            <h4 style={{marginTop: '50px', textAlign: 'center', marginBottom: '30px'}}>
              Only available for verified account
            </h4>
            <p style={{textAlign: 'center', marginBottom: '50px'}}>
              To verify you account, please send email to{' '}
              <a href='mailto:grow@giftano.com'>grow@giftano.com</a>, our team will happy to assist
              you.
            </p>
          </div>
        )}
      </GiModalBody>
    </GiModal>
  )
}

export default ModalTopup
