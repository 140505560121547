import {FC} from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
} from 'reactstrap'

export const GiRow = Row
export const GiCol = Col
export const GiButton = Button
export const GiCard = Card
export const GiCardHeader = CardHeader
export const GiCardTitle = CardTitle
export const GiCardBody = CardBody
export const GiTable = Table
export const GiLabel = Label
export const GiInput = Input
export const GiForm = Form
export const GiFormGroup = FormGroup
export const GiModal = Modal
export const GiModalHeader = ModalHeader
export const GiModalBody = ModalBody
export const GiModalFooter = ModalFooter
export const GiFormText = FormText

export const GiCardToolbar: FC<{className?: string}> = (props) => {
  return (
    <div
      className={`d-flex align-self-center ${props.className}`}
      style={{columnGap: '4px', textTransform: 'capitalize'}}
    >
      {props.children}
    </div>
  )
}
