import {Dispatch} from 'redux'
import {logout} from '../../services'
import {
  AUTH_ERROR,
  AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SET_SESSION_ID,
} from './AuthActionTypes'
import {toast} from 'react-toastify'
import {IAction} from '../store'
import {appName, getXAppName, log} from '../../helper'
import {getUserInfo, refreshSession} from '../../services/auth/AuthService'
import {messages} from '../../Messages'
import * as Sentry from '@sentry/react'

// import { getCorporateDetail } from "../../services/corporate/LegacyService";

export const getProfileAct = (corporateId?: string) => async (dispatch: Dispatch<IAction>) => {
  dispatch({type: AUTH_LOGIN})
  try {
    try {
      // get referer
      let ipClient = await fetch('/ipinfo', {
        headers: {
          'Content-Type': 'application/json',
          'x-from-app-name': getXAppName(),
        },
      })
      await ipClient.json()
    } catch (errIp) {
      alert('Failed to validate ip client')
      window.location.reload()
      return
    }
    let resExtends = await refreshSession()
    // console.log('res Extends', resExtend)
    if (resExtends.code === 200) {
      // let res = await getProfile();
      let res = await getUserInfo()
      // console.log('user info', res);
      if (res.code === 200 && res.data.profiles[appName]) {
        const profile = res.data.profiles[appName]
        if (!profile) {
          // no profile access b2b-dashboard
          toast.error(messages.no_any_profile)
          return
        }
        let profile_accesses = profile.profile_accesses || []
        // remove nulled profile detail
        profile_accesses = profile_accesses.filter((p: any) => p.corporate_detail)
        profile.profile_accesses = profile_accesses
        // get prev selected company id
        let prevCompanyId = localStorage.getItem('company_id')
        let selectedCompany = null

        // replace prevCompanyId with company from search query param if available
        const params = new URLSearchParams(window.location.search)
        const paramCompanyId = params.get('company')
        if (paramCompanyId) {
          prevCompanyId = paramCompanyId
        }
        if (corporateId) {
          prevCompanyId = corporateId
          localStorage.setItem('company_id', corporateId)
        }

        if (prevCompanyId) {
          profile_accesses.forEach((company: any) => {
            if (company.corporate_id === prevCompanyId) {
              selectedCompany = company
            }
          })
        }

        if (!selectedCompany && profile_accesses && profile_accesses.length === 1) {
          // auto select first company
          selectedCompany = profile_accesses[0]
        }

        // // get profile data from legacy
        // let resCompany = await getCorporateDetail({corporateid: selectedCompany.corporate_id});
        // // eslint-disable-next-line eqeqeq
        // if(resCompany.status == '1'){
        //     selectedCompany.corporate_detail = resCompany.data;
        // }

        dispatch({
          type: AUTH_LOGGED_IN,
          payload: {
            profile: res.data.profiles[appName],
            user_info: res.data.user_info,
            selected_company: selectedCompany,
          },
        })
      } else {
        toast.warning('Oops something wrong get profile data, please try again!', {
          autoClose: false,
          onClose: () => dispatch({type: AUTH_LOGGED_OUT}),
        })
      }
    } else {
      toast.warning('Your session has expired please re-login to access this dashboard', {
        autoClose: 3000,
        onClose: () => dispatch({type: AUTH_LOGGED_OUT}),
      })
    }
  } catch (err: any) {
    log(err)
    if (err && err.response && err.response.status === 401) {
      toast.error('Your session has expired, You will be redirected for sign-in page.', {
        autoClose: 5000,
        onClose: () => dispatch({type: AUTH_LOGGED_OUT}),
      })
    } else {
      Sentry.captureException(err)
      dispatch({type: AUTH_ERROR, payload: {error: err}})
    }
  }
}

export const setSessionId = (session_id: string) => async (dispatch: Dispatch) => {
  dispatch({type: AUTH_SET_SESSION_ID, payload: {session_id: session_id}})
}

export const logoutAct = () => async (dispatch: Dispatch) => {
  dispatch({type: AUTH_LOGOUT})
  try {
    let res = await logout()
    if (res.code === 200) {
      toast.success('Logout successfully, you will be redirected to login page!', {
        delay: 1000,
        onClose: () => {
          dispatch({type: AUTH_LOGGED_OUT})
        },
      })
    } else {
      toast.warning('Failed to logout, please try again!', {
        autoClose: false,
        onClose: () => {
          window.location.reload()
        },
      })
    }
  } catch {
    toast.error('Oops something wrong while logout, please try again!')
  }
}
