import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {actHideModalContactUs} from '../../redux/contact-us/ContactUsActions'
import {RootState} from '../../redux/rootReducer'
import GftForm from '../form/GftForm'
import {GiButton, GiCol, GiModal, GiModalBody, GiModalHeader, GiRow} from '../system-ui'
import * as Sentry from '@sentry/react'
import {CONFIG, CONNECTION} from '../../helper'
import {useLocation, useNavigate} from 'react-router-dom'

const ModalContact = () => {
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const location = useLocation()

  const user_info = useSelector((state: RootState) => state.auth.user_info)
  const navigate = useNavigate()

  const contactUs = useSelector((state: RootState) => state.contactUs)
  const dispatch = useDispatch()
  const toggle = () => {
    navigate(location.pathname)
    dispatch(actHideModalContactUs())
  }

  const [submitted, setSubmitted] = useState(false)
  useEffect(() => {
    if (contactUs.modalShow) {
      setSubmitted(false)
    }
  }, [contactUs.modalShow])

  const {corporate_id} = useSelector((state: RootState) => state.auth.company)

  return (
    <GiModal isOpen={contactUs.modalShow} toggle={toggle} autoFocus={false}>
      <GiModalHeader toggle={toggle} style={{textTransform: 'capitalize'}}>
        Enquiry
      </GiModalHeader>
      <GiModalBody>
        {submitted ? (
          <div className='text-center'>
            <h4>Thank you!</h4>
            <p>Our team will contact you as soon as possible to assist with your enquiry.</p>
          </div>
        ) : (
          <GftForm
            fields={[
              {
                type: 'text',
                name: 'name',
                label: 'Name *',
                validation: ['required'],
                value: user_info.full_name || '',
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
                inputProps: {
                  autoFocus: true,
                },
              },
              {
                type: 'email',
                name: 'email',
                label: 'Email *',
                validation: ['required'],
                value: user_info.email || '',
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
              },
              {
                type: 'phone',
                name: 'phone',
                label: 'Phone',
                value: user_info.phone_number || '',
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
              },
              {
                type: 'textarea',
                name: 'message',
                label: 'Message *',
                validation: ['required'],
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
                inputProps: {
                  rows: 5,
                },
              },
            ]}
            submitButton={(props, isSubmitting) => (
              <div className='text-right mt-4 d-flex justify-content-end'>
                <GiButton
                  className='rounded-pill'
                  type={'submit'}
                  disabled={isSubmitting}
                  color={'primary'}
                >
                  Submit
                </GiButton>
              </div>
            )}
            onSubmit={async (values) => {
              // console.log(values);

              const dataToSend = {
                from_email: 'grow@giftano.com',
                title: 'Enquiry - from giftano business ' + corporate_detail.name,
                category: 'general',
                application: 'b2b-dashboard',
                department: 'business-development',
                details: {
                  company_id: corporate_id,
                },
                description: `**Name :** ${
                  values.name
                }\n\n**From Number :** grow@giftano.com\n\n **Email :** ${
                  values.email
                }\n\n**Phone Number :** ${values.phone}\n\n**Corporate Name :** ${
                  corporate_detail.name
                }\n\n**Message :** ${values.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
              }

              try {
                const config = CONFIG({
                  url: '/support-ticket/create',
                  data: dataToSend,
                  method: 'POST',
                })
                const resp = await CONNECTION(config)
                if (resp.code === 200) {
                  setSubmitted(true)
                  navigate(location.pathname)
                }
                // let resp = await SendEmail({
                //   from_email: 'grow@giftano.com',
                //   recipients: [
                //     {
                //       email:
                //         process.env.REACT_APP_BUILD_MODE === 'PRODUCTION'
                //           ? 'grow@giftano.com'
                //           : 'dev_test_email_grow@giftano.com',
                //     },
                //   ],
                //   subject: 'Enquiry from giftano business ' + corporate_detail.name,
                //   html_tag_content: `<strong>Name :</strong> ${values.name}<br/>
                //                     <strong>Email :</strong> ${values.email}<br/>
                //                     <strong>Phone Number :</strong> ${values.phone}<br/>
                //                     <strong>Corporate Name :</strong> ${corporate_detail.name}<br/>
                //                     <strong>Message :</strong> <br/>
                //                     ${values.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
                // })
                if (resp.code === 200) {
                  setSubmitted(true)
                }
              } catch (err) {
                Sentry.captureException(err)
                console.log(err)
              }
            }}
          />
        )}
      </GiModalBody>
    </GiModal>
  )
}

export default ModalContact
