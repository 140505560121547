import {Dispatch} from 'react'
import {toast} from 'react-toastify'
import {IAction} from '../store'
import {
  ERROR_AVAILABLE_COUNTRY,
  LOADING_AVAILABLE_COUNTRY,
  SUCCESS_AVAILABLE_COUNTRY,
} from './AvailableCountryTypes'
import * as Sentry from '@sentry/react'
import {GetListAvailableCountry} from '../../services/availabel-country/AvailableCountryService'

export const ActGetListAvailableCountry = () => async (dispatch: Dispatch<IAction>) => {
  dispatch({type: LOADING_AVAILABLE_COUNTRY})
  try {
    let res = await GetListAvailableCountry()
    if (res && res.code === 200) {
      // console.log('res', res)
      return dispatch({type: SUCCESS_AVAILABLE_COUNTRY, payload: {data: res.data}})
    } else {
      toast.warning(
        (res && res.error) ||
          (res && res.message) ||
          'Oops, something wrong while loading available country data. Please reload to try again!'
      )
      dispatch({type: ERROR_AVAILABLE_COUNTRY})
    }
  } catch (error) {
    Sentry.captureException(error)
    return dispatch({type: ERROR_AVAILABLE_COUNTRY})
  }
}
