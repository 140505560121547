import {useEffect, useState} from 'react'
import {GiButton, GiModal, GiModalBody, GiModalHeader} from '../system-ui'
import './ModalMobileWarning.scss'
import {useNavigate} from 'react-router-dom'

const ModalMobileWarning = () => {
  const route = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (
      // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      //   navigator.userAgent
      // )
      window.innerWidth <= 768
    ) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
    }
  }, [route])

  return (
    <GiModal
      isOpen={openModal}
      className='modal-mobile-warning'
      centered
      toggle={() => setOpenModal(false)}
    >
      <GiModalHeader toggle={() => setOpenModal(false)}></GiModalHeader>
      <GiModalBody style={{textAlign: 'center', padding: '0 10px'}}>
        <img
          src={'https://static-cdn.giftano.com/assets/business-dashboard/icon-desktop.png'}
          alt={'Only Desktop'}
          style={{width: '70px', marginBottom: '20px'}}
        />
        <h2 className='title'>Thank you for using Giftano for Business</h2>
        <p className='text'>
          For a better experience, we recommend using the desktop version of this app.
        </p>
        <GiButton color='primary' className='btn-ok' onClick={() => setOpenModal(false)}>
          OK
        </GiButton>
      </GiModalBody>
    </GiModal>
  )
}

export default ModalMobileWarning
