import {useEffect, useState} from 'react'

// validate
type validate = 'required' | 'email' | 'number' | 'float'

// validate message
type objectValidate = {
  [key in validate]?: any
}
//  interface init field
interface initField {
  validations: Array<validate>
  name: string
  nameAlias?: string
  messages: objectValidate
  defaultValue?: any
}

// interface giForm
interface PropsGiForm {
  fields: initField[]
  onSubmited: any
}

// interface errors
interface Errors {
  [key: string]: string
}

// interface giData
interface propsGiData {
  [key: string]: any
}
// var messages = {
//   required: 'The %s field is required.',
//   match: 'The %s field does not match the %s field.',
//   email: 'The %s field must contain a valid email address.',
//   min: 'The %s field must be at least %s characters in length.',
//   max: 'The %s field must not exceed %s characters in length.',
//   match_ip: 'The %s field must contain a valid IP.',
//   match_url: 'The %s field must contain a valid URL.',
//   int:'The %s field is not valid',
//   number: 'The %s field must contain a valid number value.',
//   email: 'The %s field must contain a valid decimal value.',
// }

// interface list message
const listMessages: objectValidate = {
  required: 'The %s field is required.',
  email: 'The %s field must contain a valid email address.',
  number: 'The %s field must contain a valid number value.',
  float: 'The %s field must contain a valid number value.',
}

// validates

const helpValidate: any = {
  email: (email: any): boolean => {
    let valid: any = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    if (valid) {
      return true
    }

    return false
  },
  number: (value: any): boolean => {
    // let valid: any = String(value).match(/^[0-9]$/)
    let valid: any = numericRegex.test(value)

    if (valid) {
      return true
    }
    return false
  },
  float: (value: any): boolean => {
    let valid: any = decimalRegex.test(value)

    if (valid) {
      return true
    }
    return false
  },
}

// var ruleRegex = /^(.+?)\[(.+)\]$/,
const numericRegex = /^[0-9]+$/,
  // 	integerRegex = /^\-?[0-9]+$/,
  decimalRegex = /^[0-9]*\.?[0-9]+$/
// 	emailRegex = /^[a-zA-Z0-9.!#$%&amp;'*+\-\/=?\^_`{|}~\-]+@[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]+)*$/,
// 	alphaRegex = /^[a-z]+$/i,
// 	alphaNumericRegex = /^[a-z0-9]+$/i,
// 	alphaDashRegex = /^[a-z0-9_\-]+$/i,
// 	naturalRegex = /^[0-9]+$/i,
// 	naturalNoZeroRegex = /^[1-9][0-9]*$/i,
// 	ipRegex = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i,
// 	base64Regex = /[^a-zA-Z0-9\/\+=]/i,
// 	numericDashRegex = /^[\d\-\s]+$/,
// 	urlRegex = /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;

const GiForm = (props: PropsGiForm) => {
  const [errors, setErrors] = useState<Errors>({})
  const [isSubmited, setIsSubmited] = useState(false)
  const [giData, setGiData] = useState<propsGiData>({})

  const {fields, onSubmited} = props
  const [fieldsSet, SetFieldSet] = useState<initField[]>(fields)

  // initialize fullname
  useEffect(() => {
    const {fields} = props
    let defaultValues: any = {}
    if (fields) {
      fields.forEach((field) => {
        const {defaultValue, name} = field
        if (defaultValue) {
          defaultValues[name] = defaultValue
        }
      })
      setGiData({...defaultValues})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // adding auditional fields
  const GiPushField = (data: initField[]) => {
    let datas = [...data, ...fields]
    SetFieldSet(datas)
  }
  // initialize handle change
  const GiHandleChange = (e: any) => {
    const {target} = e

    const {value, name} = target
    setGiData({...giData, [name]: value})
  }

  // handle conditional name and value
  const GiNameAndValue = (name: any, value: any) => {
    setGiData({...giData, [name]: value})
  }
  // handle datepicker
  const GiHandleDatepicker = (date: any, name: any) => {
    setGiData({...giData, [name]: date})
  }

  // handle React Select
  const GiHanldeReactSelect = (select: any, name: any) => {
    const {value} = select
    setGiData({...giData, [name]: value})
  }

  const GiHandlerPhone = (value: any) => {
    console.log(value)
  }

  // handle passed value
  const GiPassedValue = (value: any, name: any) => {
    setGiData({...giData, [name]: value})
  }

  const GiClearValues = () => {
    setGiData({})
    setErrors({})
  }

  const GiSetErrors = (name: any, error: any) => {
    setErrors({
      ...errors,
      [name]: error,
    })
  }

  const GiFormSubmit = (e: any) => {
    e.preventDefault()
    let defaultValue: any = ''
    let message: any = ''
    let defaultMessage: any = ''
    let dataErrors: any = {}
    let isValid: boolean = false

    fieldsSet.forEach((field) => {
      const {validations, name, messages, nameAlias} = field
      defaultValue = giData[name]
      isValid = true
      validations.forEach((validate) => {
        message = ''
        defaultMessage = listMessages[validate]
        defaultMessage = defaultMessage.replace('%s', name || nameAlias)

        if (isValid) {
          switch (validate) {
            case 'required':
              if (!defaultValue) {
                message = messages[validate] !== undefined ? messages[validate] : defaultMessage
                isValid = false
              }
              break
            case 'email':
              isValid = helpValidate.email(defaultValue)
              if (!isValid) {
                message = messages[validate] !== undefined ? messages[validate] : defaultMessage
                isValid = false
              }
              break
            case 'number':
              isValid = helpValidate.number(defaultValue)
              if (!isValid) {
                message = messages[validate] !== undefined ? messages[validate] : defaultMessage
                isValid = false
              }
              break

            case 'float':
              isValid = helpValidate.float(defaultValue)
              if (!isValid) {
                message = messages[validate] !== undefined ? messages[validate] : defaultMessage
                isValid = false
              }
              break
            default:
              break
          }
        }

        if (message) {
          dataErrors[name] = message
        }
      })
    })

    console.log(Object.keys(dataErrors).length)
    console.log(dataErrors)

    if (Object.keys(dataErrors).length === 0) {
      setIsSubmited(Object.keys(dataErrors).length ? true : false)
    }

    setErrors({...dataErrors})

    onSubmited(giData, Object.keys(dataErrors).length ? false : true, dataErrors)
  }

  return {
    errors,
    GiFormSubmit,
    giData,
    GiHandleChange,
    GiHanldeReactSelect,
    GiHandleDatepicker,
    fields,
    GiPushField,
    GiPassedValue,
    GiClearValues,
    GiNameAndValue,
    GiHandlerPhone,
    isSubmited,
    GiSetErrors,
  }
}
export {GiForm}
