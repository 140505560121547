import {FaChevronLeft} from 'react-icons/fa6'
import {GiButton} from '../system-ui'

interface BackButtonProps {
  action: () => void
  style?: any
  size?: string
}
const BackButton = (props: BackButtonProps) => {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        ...props.style,
      }}
    >
      <GiButton
        outline
        color='primary'
        size={props.size || undefined}
        style={{
          paddingRight: '15px',
          paddingLeft: '15px',
          borderColor: '#C4C4C4',
        }}
        onClick={props.action}
      >
        <FaChevronLeft />
      </GiButton>
    </div>
  )
}

export default BackButton
