/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/redux/rootReducer'
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap'
// import {Button} from 'reactstrap'
import './AsideDefault.scss'
import {FaCaretRight} from 'react-icons/fa'
import React from 'react'
import {BsDot} from 'react-icons/bs'

interface MenuActProps {
  label: any
  shortLabel?: string
  active: boolean
  id: string
  icon: any
  onClick: () => void
}

const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const {aside} = config
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const strapi = useSelector((state: RootState) => state.strapi)

  const navigate = useNavigate()

  const [sendGiftButtonDropdown, setSendGiftButtonDropdown] = React.useState<MenuActProps[]>([
    {
      label: (
        <>
          <div className='lb-title'>Send Digital Gift</div>
          <div className='lb-subtitle'>(Auto Delivery)</div>
        </>
      ),
      shortLabel: 'Send Gift',
      active: true,
      id: 'send-gift',
      icon: (
        <BsDot
          className='ms-2 position-absolute'
          style={{
            left: '0',
            top: '0',
            bottom: '0',
            marginTop: '12px',
            marginBottom: 'auto',
          }}
          size={20}
        />
      ),
      onClick: () => {
        navigate('/send-gifts/create-order')
      },
    },
    {
      label: (
        <>
          <div className='lb-title'>Buy Gift Card Link(s)</div>
          <div className='lb-subtitle'>(excel file)</div>
        </>
      ),
      icon: (
        <BsDot
          className='ms-2 position-absolute'
          style={{
            left: '0',
            top: '0',
            bottom: '0',
            marginTop: '12px',
            marginBottom: 'auto',
          }}
          size={20}
        />
      ),
      active: false,
      id: 'buy-link',
      onClick: () => {
        navigate('/send-gifts/buy-link')
      },
    },
    // {
    //   label: 'Scan to Reward',
    //   icon: (
    //     <FaCaretRight
    //       className='ms-2 position-absolute'
    //       style={{
    //         right: '10px',
    //         top: '0',
    //         bottom: '0',
    //         marginTop: 'auto',
    //         marginBottom: 'auto',
    //       }}
    //     />
    //   ),
    //   active: false,
    //   id: 'qr-gift',
    //   onClick: () => {
    //     navigate('/send-gifts/activate-qr-gift')
    //   },
    // },
  ])

  useEffect(() => {
    let sgbutton = sendGiftButtonDropdown
    if (strapi && strapi.data) {
      if (strapi?.data.enable_buy_link) {
        sgbutton = sgbutton.map((item) => (item.id === 'buy-link' ? {...item, active: true} : item))
      }
      if (strapi?.data.enable_qr_gift) {
        sgbutton = sgbutton.map((item) => (item.id === 'qr-gift' ? {...item, active: true} : item))
      }
    }
    if (
      corporate_detail &&
      corporate_detail['transaction-settings'] &&
      corporate_detail['transaction-settings']['gfb-enable-buy-links']
    ) {
      sgbutton = sgbutton.map((item) =>
        item.id === 'buy-link'
          ? {
              ...item,
              active: corporate_detail['transaction-settings']['gfb-enable-buy-links'] === '1',
            }
          : item
      )
    }
    setSendGiftButtonDropdown(sgbutton)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strapi, corporate_detail])

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      data-kt-menu-expand='false'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-25px logo'
              src='https://static-cdn.giftano.com/assets/giftano-logo-white.png'
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-25px logo'
              // src={toAbsoluteUrl('/media/logos/logo-1.svg')}
              src='https://static-cdn.giftano.com/assets/giftano-logo-horizontal.png'
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-90'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}
      {/* additional button*/}

      {corporate_detail.type !== 'partnership' &&
        corporate_detail.is_api_account?.toString() !== '1' &&
        corporate_detail['transaction-settings']['gfb-disable-order'] !== '1' &&
        sendGiftButtonDropdown &&
        sendGiftButtonDropdown.filter((item) => item.active === true).length > 0 && (
          <>
            {/* <Link to={'/send-gifts/create-order'} className='btn btn-warning rounded-pill m-5'>
            Send Gift
          </Link> */}
            <UncontrolledDropdown
              className='btn btn-warning rounded-2 m-5 p-0 dropdown-modified'
              group
              disabled={
                corporate_detail?.country === '' || corporate_detail['currency-code'] === ''
              }
            >
              {sendGiftButtonDropdown.filter((item) => item.active === true).length === 1 && (
                <Button
                  color='warning'
                  className={
                    sendGiftButtonDropdown.filter((item) => item.active === true).length > 1
                      ? 'rounded-left'
                      : 'rounded-2'
                  }
                  disabled={
                    corporate_detail?.country === '' || corporate_detail['currency-code'] === ''
                  }
                  style={{
                    // borderTopLeftRadius: '50px',
                    // borderBottomLeftRadius: '50px',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                  }}
                  onClick={sendGiftButtonDropdown[0].onClick}
                >
                  Send Digital Gift
                </Button>
              )}
              {sendGiftButtonDropdown.filter((item) => item.active === true).length > 1 && (
                <>
                  <DropdownToggle caret color='warning'>
                    Send Digital Gift
                  </DropdownToggle>
                  <DropdownMenu>
                    {/* <DropdownItem
                      className='position-relative'
                      onClick={() => {
                        navigate('/send-gifts/create-order')
                      }}
                    >
                      Send Gift (Auto Delivery){' '}
                      <FaCaretRight
                        className='ms-2 position-absolute'
                        style={{
                          right: '10px',
                          top: '0',
                          bottom: '0',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}
                      />
                    </DropdownItem>
                    <hr className='my-3' />
                    <DropdownItem
                      className='position-relative'
                      onClick={() => {
                        navigate('/send-gifts/buy-link')
                      }}
                    >
                      Buy Gift Card Link(s) (excel file){' '}
                      <FaCaretRight
                        className='ms-2 position-absolute'
                        style={{
                          right: '10px',
                          top: '0',
                          bottom: '0',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}
                      />
                    </DropdownItem>
                    <DropdownItem
                      className='position-relative'
                      onClick={() => {
                        navigate('/send-gifts/activate-qr-gift')
                      }}
                    >
                      Activate Gift Card
                      <FaCaretRight
                        className='ms-2 position-absolute'
                        style={{
                          right: '10px',
                          top: '0',
                          bottom: '0',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}
                      />
                    </DropdownItem> */}
                    {sendGiftButtonDropdown
                      .filter((item) => item.active === true)
                      .map((item, index) => {
                        return (
                          <div key={index}>
                            <DropdownItem
                              key={index}
                              className='position-relative'
                              onClick={item.onClick}
                            >
                              {item.label} {item.icon}
                            </DropdownItem>
                            {/* {index === 0 && <hr className='my-3' />} */}
                          </div>
                        )
                      })}
                  </DropdownMenu>
                </>
              )}
            </UncontrolledDropdown>
          </>
        )}

      {/* end additional button*/}
      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'></div>
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
