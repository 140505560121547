import React from 'react'
import {FaChevronRight} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {ListGroup, ListGroupItem} from 'reactstrap'
import {GiCard, GiCardBody, GiCardHeader, GiCardTitle} from '../../components/system-ui'
import {AUTH_SET_COMPANY} from '../../redux/auth/AuthActionTypes'
import {RootState} from '../../redux/rootReducer'
import './style.scss'
import Country from '../../assets/json/country.json'
// import { toAbsoluteUrl } from '../../../_metronic/helpers/AssetHelpers'

const SelectCorporatePage = () => {
  const profile = useSelector((state: RootState) => state.auth.profile)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <div className='select-corporate-page'>
      <GiCard>
        <GiCardHeader>
          <GiCardTitle style={{color: 'var(--gf-primary-300)'}}>Select Corporate</GiCardTitle>
        </GiCardHeader>
        <GiCardBody>
          <ListGroup className='corporate-list'>
            {profile &&
              profile.profile_accesses &&
              profile.profile_accesses
                .filter((a: any) => a.corporate_detail)
                .map((a: any, itemIdx: number) => {
                  let countrySelected = Country.find(
                    (obj) => obj.alpha2 === a.corporate_detail.country
                  )
                  return (
                    <ListGroupItem
                      className='corporate-item'
                      key={'itemNumber_' + itemIdx}
                      onClick={() => {
                        dispatch({type: AUTH_SET_COMPANY, payload: {company: a}})
                        navigate('/dashboard')
                      }}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='corporate-image-container'>
                          {a.corporate_detail.logo ? (
                            <img
                              src={a.corporate_detail.logo}
                              alt={a.corporate_detail.name}
                              className='logo'
                            />
                          ) : (
                            <p className='logo'>
                              {a.corporate_detail.name
                                .split(' ')
                                .map((word: string) => word.charAt(0))
                                .join('')}
                            </p>
                          )}

                          {a.corporate_detail.country && (
                            <div className='country-flag' style={{fontWeight: 700}}>
                              {/* Country : */}
                              <img
                                src={require('../../../../public/media/flags/' +
                                  countrySelected?.alpha2?.toLowerCase() +
                                  '.svg')}
                                width='22px'
                                alt={countrySelected?.name}
                              />
                              {/* <span style={{fontSize: '20px'}}>{countrySelected?.emoji}</span>{' '} */}
                              {/* {countrySelected?.name} */}
                            </div>
                          )}
                        </div>

                        <div>
                          <h4 className='corporate-name'>{a.corporate_detail.name}</h4>
                          {a.corporate_detail.department ? (
                            <div className='corporate-information'>
                              <div className='label'>Department :</div>
                              <div className='information'> {a.corporate_detail.department}</div>
                            </div>
                          ) : (
                            <div className='corporate-information'>
                              <div className='label'>Department :</div>
                              <div className='information'>_</div>
                            </div>
                          )}
                          {/* {a.role_detail.name && (
                            <p className='role-detail'>Department : {a.role_detail.name}</p>
                          )} */}
                          {a.corporate_detail.uen && (
                            <div className='corporate-information'>
                              <div className='label'>UEN :</div>
                              <div className='information'>{a.corporate_detail.uen}</div>{' '}
                            </div>
                          )}

                          {/* <p>{a.corporate_detail.address}</p> */}
                        </div>
                      </div>
                      <div className='pointer-icon'>
                        <FaChevronRight color='var(--gf-primary-300)' />
                      </div>
                    </ListGroupItem>
                  )
                })}
          </ListGroup>
        </GiCardBody>
      </GiCard>
    </div>
  )
}

export default SelectCorporatePage
