import {IAction} from '../store'
import {
  ERROR_PATTREN_PASSWORD,
  LOADING_PATTREN_PASSWORD,
  SUCCESS_PATTREN_PASSWORD,
} from './PasswordTypes'

export interface IAuthStateStatePasswordPattren {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error' | 'default'
  error?: string
}

const InitialState: IAuthStateStatePasswordPattren = {
  data: [],
  status: 'default',
}

const PattrenPassword = (state = InitialState, action: IAction) => {
  let newState = {...state}

  switch (action.type) {
    case SUCCESS_PATTREN_PASSWORD:
      newState.status = 'success'
      newState.data = action.payload
      break
    case LOADING_PATTREN_PASSWORD:
      newState.status = 'loading'
      break
    case ERROR_PATTREN_PASSWORD:
      newState.status = 'error'
      break
    default:
  }

  return newState
}

export default PattrenPassword
