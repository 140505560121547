import {IAction} from '../store'
import {ERROR_CATALOGUE, LOADING_CATALOGUE, SUCCESS_CATALOGUE} from './CatalogueTypes'

interface IResult {
  merchants: any
  products: any[]
  tags: any
}

interface ICatalogue {
  result: IResult
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: string
  uncombined_gifts?: any[]
  merchant_only_email?: any[]
}

const InitialState: ICatalogue = {
  result: {
    products: [],
    tags: {},
    merchants: {},
  },
  status: 'pending',
}

const CatalogueReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}

  switch (action.type) {
    case LOADING_CATALOGUE:
      newState.status = 'loading'
      break
    case SUCCESS_CATALOGUE:
      newState.result = action.payload.data
      // uncombine/populate all gifts
      // and populate merchant only have email
      newState.uncombined_gifts = []
      newState.merchant_only_email = []
      newState.result.products.forEach((p: any) => {
        if (p.products) {
          p.products.forEach((pp: any) => {
            let foundEmail = false
            if (p.dymethods) {
              p.dymethods.forEach((d: any) => {
                if (d.value === 'email' || d.value === 'ecard') {
                  foundEmail = true
                }
              })
            }
            if (foundEmail) {
              newState.uncombined_gifts?.push(pp)
              if (newState.merchant_only_email?.indexOf(pp.merchant_id) === -1) {
                newState.merchant_only_email.push(pp.merchant_id)
              }
            }
          })
        } else {
          let foundEmail = false
          if (p.dymethods) {
            p.dymethods.forEach((d: any) => {
              if (d.value === 'email' || d.value === 'ecard') {
                foundEmail = true
              }
            })
          }
          if (foundEmail) {
            newState.uncombined_gifts?.push(p)
            if (newState.merchant_only_email?.indexOf(p.merchant_id) === -1) {
              newState.merchant_only_email.push(p.merchant_id)
            }
          }
        }
      })
      newState.merchant_only_email = newState.merchant_only_email.map((m) => ({
        ...newState.result.merchants[m],
      }))
      newState.status = 'success'
      break
    case ERROR_CATALOGUE:
      newState.result = {
        products: [],
        tags: {},
        merchants: {},
      }
      newState.merchant_only_email = []
      newState.uncombined_gifts = []
      newState.status = 'error'
      break
    default:
      break
  }

  return newState
}

export default CatalogueReducer
