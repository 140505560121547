import {IAction} from '../store'
import {
  LOADING_AVAILABLE_COUNTRY,
  SUCCESS_AVAILABLE_COUNTRY,
  ERROR_AVAILABLE_COUNTRY,
} from './AvailableCountryTypes'

interface IAvailableCountry {
  result: []
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: string
}

const InitialState: IAvailableCountry = {
  result: [],
  status: 'pending',
}

const AvailableCountryReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}

  switch (action.type) {
    case LOADING_AVAILABLE_COUNTRY:
      newState.status = 'loading'
      break
    case SUCCESS_AVAILABLE_COUNTRY:
      newState.result = action.payload.data
      newState.status = 'success'
      break
    case ERROR_AVAILABLE_COUNTRY:
      newState.status = 'error'
      break
    default:
      break
  }

  return newState
}

export default AvailableCountryReducer
