import {IAction} from '../store'
import {SET_CATALOGUE_OTHER_COUNTRY} from './CatalogueOtherCountryTypes'

interface IResult {
  merchants: any
  products: any[]
  tags: any
}

interface ICatalogueOtherCountry {
  result: IResult
  uncombined_gifts?: any[]
  merchant_only_email?: any[]
  country_code?: string
}

interface ICatalogueOtherCountryWrapper {
  data: ICatalogueOtherCountry[]
}

const InitialState: ICatalogueOtherCountryWrapper = {
  data: [],
}

const CatalogueOtherCountryReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case SET_CATALOGUE_OTHER_COUNTRY:
      let data: ICatalogueOtherCountry = {
        result: {
          products: [],
          tags: {},
          merchants: {},
        },
        uncombined_gifts: [],
        merchant_only_email: [],
        country_code: action.payload.data.country,
      }
      data.result = action.payload.data
      // uncombine/populate all gifts
      // and populate merchant only have email
      data.uncombined_gifts = []
      data.merchant_only_email = []
      data.result.products.forEach((p: any) => {
        if (p.products) {
          p.products.forEach((pp: any) => {
            let foundEmail = false
            if (p.dymethods) {
              p.dymethods.forEach((d: any) => {
                if (d.value === 'email' || d.value === 'ecard') {
                  foundEmail = true
                }
              })
            }
            if (foundEmail) {
              data.uncombined_gifts?.push(pp)
              if (data.merchant_only_email?.indexOf(pp.merchant_id) === -1) {
                data.merchant_only_email.push(pp.merchant_id)
              }
            }
          })
        } else {
          let foundEmail = false
          if (p.dymethods) {
            p.dymethods.forEach((d: any) => {
              if (d.value === 'email' || d.value === 'ecard') {
                foundEmail = true
              }
            })
          }
          if (foundEmail) {
            data.uncombined_gifts?.push(p)
            if (data.merchant_only_email?.indexOf(p.merchant_id) === -1) {
              data.merchant_only_email.push(p.merchant_id)
            }
          }
        }
      })
      data.merchant_only_email = data.merchant_only_email.map((m) => ({
        ...data.result.merchants[m],
      }))
      newState.data.push(data)
      break
    default:
      break
  }

  return newState
}

export default CatalogueOtherCountryReducer
